var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-5 bg-white rounded-xl shadow-xl mb-4"},[_c('div',{staticClass:"flex gap-2"},[_c('a-icon',{staticClass:"mt-1",style:({
        fontSize: '20px',
        color: 'gray',
      }),attrs:{"type":"filter"}}),_c('h2',{staticClass:"text-left text-lg text-gray-600 font-bold"},[_vm._v(" Filtros de búsqueda ")])],1),(!_vm.loading)?_c('div',{staticClass:"flex flex-col gap-2"},[_c('div',{staticClass:"w-full flex"},[_c('a-button',{staticClass:"w-1/2 gap-4 sm:mt-auto mt-3 lg:w-1/6",attrs:{"type":"primary","shape":"round"},on:{"click":_vm.onSearch}},[_c('a-icon',{attrs:{"type":"sliders"}}),_vm._v(" "+_vm._s(_vm.showModal ? "Ocultar" : "Desplegar")+" filtros ")],1),_c('BaseRangeDate',{staticClass:"w-1/2 lg:w-2/6 lg:ml-5",attrs:{"allowClear":false},on:{"notify-dates":_vm.generalFilter}}),(_vm.selectedKeys > 0)?_c('div',{staticClass:"xl:col-span-2 flex justify-end mt-auto"},[_c('a-button',{staticClass:"ml-7",attrs:{"type":"primary","ghost":"","shape":"round"},on:{"click":_vm.cleanFilters}},[_vm._v("Limpiar filtros")])],1):_vm._e()],1),_c('div',{staticClass:"w-full mt-3 px-8"},[(_vm.selectedKeys === 0)?_c('span',{staticClass:"text-gray-400"},[_vm._v("No hay filtros seleccionados")]):(_vm.flag && _vm.selectedKeys <= 5)?_vm._l((_vm.filterSet),function(filter,idx){return _c('div',{key:idx,staticClass:"flex"},[(filter.data)?[(
                filter.type !== 'DATETIME' &&
                filter.type !== 'TEXT' &&
                filter.type !== 'NUMBER' &&
                filter.type !== 'SELECT-CHECK'
              )?_c('div',_vm._l((filter.data),function(tag,j){return _c('a-tag',{key:j,attrs:{"color":"blue","closable":""},on:{"close":function($event){return _vm.deleteFilter(idx, j)}}},[_vm._v(_vm._s(tag))])}),1):(
                filter.type === 'SELECT-CHECK' && filter.data.length > 0
              )?_c('div',[_c('a-tag',{attrs:{"color":"blue","closable":""},on:{"close":function($event){return _vm.deleteFilter(idx, -1)}}},[_vm._v(" "+_vm._s(_vm.getTag(filter))+" ")])],1):(filter.type !== 'SELECT-CHECK')?_c('div',[_c('a-tag',{attrs:{"color":"blue","closable":""},on:{"close":function($event){return _vm.deleteFilter(idx, -1)}}},[_vm._v(" "+_vm._s(Array.isArray(filter.data) ? "" : filter.description)+" "+_vm._s(Array.isArray(filter.data) ? filter.data[0] + " prórrogas" : filter.data))])],1):_vm._e()]:_vm._e()],2)}):[_c('a-collapse',{staticClass:"bg-white border-hidden",attrs:{"bordered":false}},[_c('a-collapse-panel',{attrs:{"header":`Filtros aplicados  ${_vm.selectedKeys}`}},_vm._l((_vm.filterSet),function(filter,idx){return _c('div',{key:idx,staticClass:"flex"},[(filter.data)?[(
                    filter.type !== 'DATETIME' &&
                    filter.type !== 'TEXT' &&
                    filter.type !== 'NUMBER' &&
                    filter.type !== 'SELECT-CHECK'
                  )?_c('div',_vm._l((filter.data),function(tag,j){return _c('a-tag',{key:j,attrs:{"color":"blue","closable":""},on:{"close":function($event){return _vm.deleteFilter(idx, j)}}},[_vm._v(_vm._s(tag))])}),1):(
                    filter.type === 'SELECT-CHECK' && filter.data.length > 0
                  )?_c('div',[_c('a-tag',{attrs:{"color":"blue","closable":""},on:{"close":function($event){return _vm.deleteFilter(idx, -1)}}},[_vm._v(" "+_vm._s(_vm.getTag(filter))+" ")])],1):(filter.type !== 'SELECT-CHECK')?_c('div',[_c('a-tag',{attrs:{"color":"blue","closable":""},on:{"close":function($event){return _vm.deleteFilter(idx, -1)}}},[_vm._v(" "+_vm._s(Array.isArray(filter.data) ? "" : filter.description)+" "+_vm._s(Array.isArray(filter.data) ? filter.data[0] + " prórrogas" : filter.data))])],1):_vm._e()]:_vm._e()],2)}),0)],1)]],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],staticClass:"filters w-full text-left text-md"},[_c('ul',{staticClass:"main-menu main overflow-auto overscroll-x-none"},_vm._l((_vm.getAllGroups),function(group){return _c('li',{key:group,staticClass:"menu-item hover:bg-gray-200 focus:bg-blue-100 hover:border-xs rounded-md p-1 flex justify-between",class:group === _vm.groupActive
              ? 'bg-blue-700 text-white  hover:bg-blue-800 rounded-md pl-4 font-bold'
              : '',on:{"click":function($event){return _vm.active(group)}}},[_c('span',{},[_vm._v(_vm._s(_vm._f("capitalize")(group))+" ")]),_c('a-icon',{staticClass:"mt-0.5",attrs:{"type":"caret-right"}})],1)}),0),_c('ul',{staticClass:"center-menu overflow-auto text-left overscroll-x-none"},[_vm._l((_vm.groupFilters),function(item){return [(item.group === _vm.groupActive)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(
              (_vm.relColombia.includes(item.name) && _vm.flagColombia) ||
              !_vm.relColombia.includes(item.name)
            ),expression:"\n              (relColombia.includes(item.name) && flagColombia) ||\n              !relColombia.includes(item.name)\n            "}],key:item.name,staticClass:"menu-item hover:bg-gray-200 focus:bg-blue-100 border-xl flex justify-between rounded-md p-1 my-1",class:item === _vm.optionActual
                ? 'bg-blue-700 text-white  hover:bg-blue-800 rounded-md pl-4 font-bold'
                : '',on:{"click":function($event){return _vm.optionActive(item)}}},[_c('span',{},[_vm._v(" "+_vm._s(item.description))]),_c('a-icon',{staticClass:"mt-0.5",attrs:{"type":"caret-right"}})],1):_vm._e()]})],2),_c('ul',{staticClass:"left overflow-auto",on:{"change":function($event){return _vm.updateTags()},"click":function($event){return _vm.updateTags()}}},[[_c('li',_vm._l((_vm.groupFilters),function(item){return _c('div',{key:item.name,staticClass:"filter-inputs"},[_c('keep-alive',[(item.type === 'TEXT')?_c('BaseInput',{class:item.name === _vm.optionActual.name ? '' : 'hidden',attrs:{"label":item.description},model:{value:(_vm.filterSet[item.name].data),callback:function ($$v) {_vm.$set(_vm.filterSet[item.name], "data", $$v)},expression:"filterSet[item.name].data"}}):_vm._e(),(item.type === 'DATETIME')?_c('Calendar',{class:item.name === _vm.optionActual.name ? '' : 'hidden',on:{"selected-date":function($event){return _vm.dateSelected($event, item.name)}}}):_vm._e(),(item.type === 'SELECT-CHECK')?_c('BaseCheckbox',{class:item.name === _vm.optionActual.name ? '' : 'hidden',attrs:{"id":item.name,"name":item.name,"label":item.description,"options":item.options},on:{"change":_vm.totalFiltersSelected},model:{value:(_vm.filterSet[item.name].data),callback:function ($$v) {_vm.$set(_vm.filterSet[item.name], "data", $$v)},expression:"filterSet[item.name].data"}}):_vm._e(),(
                    item.type === 'NUMBER' && item.name !== 'prorroga_queja'
                  )?_c('BaseRadio',{class:item.name === _vm.optionActual.name ? '' : 'hidden',attrs:{"cleanable":"true","id":item.name,"name":item.name,"label":item.description,"radioOptions":item.options},on:{"cleaned":function($event){return _vm.radioCleaned($event)},"change":_vm.totalFiltersSelected},model:{value:(_vm.filterSet[item.name].data),callback:function ($$v) {_vm.$set(_vm.filterSet[item.name], "data", $$v)},expression:"filterSet[item.name].data"}}):_vm._e(),(
                    item.name === 'prorroga_queja' && item.type === 'NUMBER'
                  )?_c('Slider',{class:item.name === _vm.optionActual.name ? '' : 'hidden',attrs:{"label":"Cantidad de prórrogas","marks":item.options,"defaultValue":6,"max":12},on:{"onChange":function($event){return _vm.sliderChange($event, item.name)}}}):_vm._e(),(
                    item.type === 'SELECT' &&
                    ((_vm.relColombia.includes(item.name) && _vm.flagColombia) ||
                      !_vm.relColombia.includes(item.name))
                  )?_c('BaseMultiSelect',{class:item.name === _vm.optionActual.name ? '' : 'hidden',attrs:{"valuesSelected":_vm.filterSet[item.name].data,"data":item.data,"filter":"","label":item.name},on:{"notify-select":function($event){return _vm.setMultiSelect($event, item.name)}}}):_vm._e()],1)],1)}),0)]],2)]),_c('div',{staticClass:"sm:flex gap-3 justify-end flex-end mt-3"},[(_vm.showModal)?_c('a-button',{staticClass:"w-1/4 h-10 mt-auto text-sm",attrs:{"type":"danger","ghost":"","shape":"round"},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_c('a-icon',{attrs:{"type":"cancel"}}),_vm._v(" Cancelar ")],1):_vm._e(),(_vm.showModal)?_c('a-button',{staticClass:"w-1/4 h-10 mt-auto text-sm",attrs:{"shape":"round","type":"primary"},on:{"click":_vm.applyFilters}},[_c('a-icon',{attrs:{"type":"sliders"}}),_vm._v(" Aplicar filtros ")],1):_vm._e()],1)]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"flex justify-center items-center my-auto"},[_c('a-spin',[_c('a-icon',{staticStyle:{"font-size":"36px"},attrs:{"slot":"indicator","type":"loading","spin":""},slot:"indicator"})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }