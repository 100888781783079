<template>
  <div class="p-5 bg-white rounded-xl shadow-xl mb-4">
    <div class="flex gap-2">
      <a-icon
        type="filter"
        class="mt-1"
        :style="{
          fontSize: '20px',
          color: 'gray',
        }"
      ></a-icon>
      <h2 class="text-left text-lg text-gray-600 font-bold">
        Filtros de búsqueda
      </h2>
    </div>

    <div v-if="!loading" class="flex flex-col gap-2">
      <div class="w-full flex">
        <a-button
          @click="onSearch"
          type="primary"
          shape="round"
          class="w-1/2 gap-4 sm:mt-auto mt-3 lg:w-1/6"
        >
          <a-icon type="sliders"></a-icon>
          {{ showModal ? "Ocultar" : "Desplegar" }} filtros
        </a-button>
        <BaseRangeDate
          :allowClear=false
          class="w-1/2 lg:w-2/6 lg:ml-5"
          @notify-dates="generalFilter"
        />
        <div
          class="xl:col-span-2 flex justify-end mt-auto"
          v-if="selectedKeys > 0"
        >
          <a-button
            type="primary"
            ghost
            shape="round"
            class="ml-7"
            @click="cleanFilters"
            >Limpiar filtros</a-button
          >
        </div>
      </div>
      <div class="w-full mt-3 px-8">
        <span v-if="selectedKeys === 0" class="text-gray-400"
          >No hay filtros seleccionados</span
        >
        <template v-else-if="flag && selectedKeys <= 5">
          <div v-for="(filter, idx) in filterSet" :key="idx" class="flex">
            <template v-if="filter.data">
              <div
                v-if="
                  filter.type !== 'DATETIME' &&
                  filter.type !== 'TEXT' &&
                  filter.type !== 'NUMBER' &&
                  filter.type !== 'SELECT-CHECK'
                "
              >
                <a-tag
                  color="blue"
                  v-for="(tag, j) in filter.data"
                  :key="j"
                  closable
                  @close="deleteFilter(idx, j)"
                  >{{ tag }}</a-tag
                >
              </div>
              <div
                v-else-if="
                  filter.type === 'SELECT-CHECK' && filter.data.length > 0
                "
              >
                <a-tag color="blue" closable @close="deleteFilter(idx, -1)">
                  <!-- {{ Object.keys(filterSet)[idx] }}{{ idx }} -->
                  {{ getTag(filter) }}
                </a-tag>
              </div>
              <div v-else-if="filter.type !== 'SELECT-CHECK'">
                <a-tag color="blue" closable @close="deleteFilter(idx, -1)">
                  {{ Array.isArray(filter.data) ? "" : filter.description }}
                  {{
                    Array.isArray(filter.data)
                      ? filter.data[0] + " prórrogas"
                      : filter.data
                  }}</a-tag
                >
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <a-collapse :bordered="false" class="bg-white border-hidden">
            <a-collapse-panel :header="`Filtros aplicados  ${selectedKeys}`">
              <div v-for="(filter, idx) in filterSet" :key="idx" class="flex">
                <template v-if="filter.data">
                  <div
                    v-if="
                      filter.type !== 'DATETIME' &&
                      filter.type !== 'TEXT' &&
                      filter.type !== 'NUMBER' &&
                      filter.type !== 'SELECT-CHECK'
                    "
                  >
                    <a-tag
                      color="blue"
                      v-for="(tag, j) in filter.data"
                      :key="j"
                      closable
                      @close="deleteFilter(idx, j)"
                      >{{ tag }}</a-tag
                    >
                  </div>
                  <div
                    v-else-if="
                      filter.type === 'SELECT-CHECK' && filter.data.length > 0
                    "
                  >
                    <a-tag color="blue" closable @close="deleteFilter(idx, -1)">
                      <!-- {{ Object.keys(filterSet)[idx] }}{{ idx }} -->
                      {{ getTag(filter) }}
                    </a-tag>
                  </div>
                  <div v-else-if="filter.type !== 'SELECT-CHECK'">
                    <a-tag color="blue" closable @close="deleteFilter(idx, -1)">
                      {{ Array.isArray(filter.data) ? "" : filter.description }}
                      {{
                        Array.isArray(filter.data)
                          ? filter.data[0] + " prórrogas"
                          : filter.data
                      }}</a-tag
                    >
                  </div>
                </template>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </template>
      </div>
      <div class="filters w-full text-left text-md" v-show="showModal">
        <ul class="main-menu main overflow-auto overscroll-x-none">
          <li
            class="menu-item hover:bg-gray-200 focus:bg-blue-100 hover:border-xs rounded-md p-1 flex justify-between"
            :class="
              group === groupActive
                ? 'bg-blue-700 text-white  hover:bg-blue-800 rounded-md pl-4 font-bold'
                : ''
            "
            v-for="group in getAllGroups"
            :key="group"
            @click="active(group)"
          >
            <span class="">{{ group | capitalize }} </span>
            <a-icon type="caret-right" class="mt-0.5"></a-icon>
          </li>
        </ul>
        <ul class="center-menu overflow-auto text-left overscroll-x-none">
          <template v-for="item in groupFilters">
            <li
              @click="optionActive(item)"
              :class="
                item === optionActual
                  ? 'bg-blue-700 text-white  hover:bg-blue-800 rounded-md pl-4 font-bold'
                  : ''
              "
              v-if="item.group === groupActive"
              :key="item.name"
              v-show="
                (relColombia.includes(item.name) && flagColombia) ||
                !relColombia.includes(item.name)
              "
              class="menu-item hover:bg-gray-200 focus:bg-blue-100 border-xl flex justify-between rounded-md p-1 my-1"
            >
              <span class=""> {{ item.description }}</span>
              <a-icon type="caret-right" class="mt-0.5"></a-icon>
            </li>
          </template>
        </ul>
        <ul
          class="left overflow-auto"
          @change="updateTags()"
          @click="updateTags()"
        >
          <template>
            <li>
              <div
                v-for="item in groupFilters"
                :key="item.name"
                class="filter-inputs"
              >
                <keep-alive>
                  <BaseInput
                    v-if="item.type === 'TEXT'"
                    :label="item.description"
                    v-model="filterSet[item.name].data"
                    :class="item.name === optionActual.name ? '' : 'hidden'"
                  />
                  <Calendar
                    v-if="item.type === 'DATETIME'"
                    @selected-date="dateSelected($event, item.name)"
                    :class="item.name === optionActual.name ? '' : 'hidden'"
                  />
                  <BaseCheckbox
                    :id="item.name"
                    :name="item.name"
                    v-if="item.type === 'SELECT-CHECK'"
                    :label="item.description"
                    :options="item.options"
                    v-model="filterSet[item.name].data"
                    :class="item.name === optionActual.name ? '' : 'hidden'"
                    @change="totalFiltersSelected"
                  />
                  <BaseRadio
                    cleanable="true"
                    @cleaned="radioCleaned($event)"
                    :id="item.name"
                    :name="item.name"
                    v-if="
                      item.type === 'NUMBER' && item.name !== 'prorroga_queja'
                    "
                    :label="item.description"
                    :radioOptions="item.options"
                    v-model="filterSet[item.name].data"
                    :class="item.name === optionActual.name ? '' : 'hidden'"
                    @change="totalFiltersSelected"
                  />

                  <Slider
                    v-if="
                      item.name === 'prorroga_queja' && item.type === 'NUMBER'
                    "
                    label="Cantidad de prórrogas"
                    :marks="item.options"
                    :defaultValue="6"
                    :max="12"
                    @onChange="sliderChange($event, item.name)"
                    :class="item.name === optionActual.name ? '' : 'hidden'"
                  />
                  <BaseMultiSelect
                    v-if="
                      item.type === 'SELECT' &&
                      ((relColombia.includes(item.name) && flagColombia) ||
                        !relColombia.includes(item.name))
                    "
                    :valuesSelected="filterSet[item.name].data"
                    :class="item.name === optionActual.name ? '' : 'hidden'"
                    :data="item.data"
                    filter
                    :label="item.name"
                    @notify-select="setMultiSelect($event, item.name)"
                  />
                </keep-alive>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <div class="sm:flex gap-3 justify-end flex-end mt-3">
        <a-button
          v-if="showModal"
          @click="showModal = !showModal"
          type="danger"
          ghost
          shape="round"
          class="w-1/4 h-10 mt-auto text-sm"
        >
          <a-icon type="cancel" />
          Cancelar
        </a-button>
        <a-button
          v-if="showModal"
          @click="applyFilters"
          shape="round"
          type="primary"
          class="w-1/4 h-10 mt-auto text-sm"
        >
          <a-icon type="sliders" />
          Aplicar filtros
        </a-button>
      </div>
    </div>
    <div v-if="loading" class="flex justify-center items-center my-auto">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 36px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Calendar from "@/components/UI/BaseInput/Calendar.vue";

//Plantilla para generar los grupos correspondientes de los filtros
const templateJSON = {
  nombre_entidad: "entidad",
  nombre_tipo_entidad: "entidad",

  nombre_pais: "geográfica",
  nombre_departamento: "geográfica",
  nombre_municipio: "geográfica",

  fecha_creacion: "fecha",
  fecha_registro: "fecha",
  fecha_actualizacion: "fecha",
  fecha_cierre: "fecha",

  replica: "gestión",
  desistimiento_queja: "gestión",
  tutela: "gestión",
  escalamiento_DCF: "gestión",

  sexo: "usuario",
  lgbtiq: "usuario",
  condicion_especial: "usuario",
  numero_id_CF: "usuario",

  anexo_queja: "queja",
  acknowledgment: "queja",
  producto_nombre: "queja",
  macro_motivo: "queja",
  queja_expres: "queja",
  estado: "queja",
  is_sync: "queja",
  codigo_queja: "queja",
  tipo_fraude: "queja",
  modalidad_fraude: "queja",

  nombre_ente_control: "otro",
  prorroga_queja: "otro",
  producto_digital: "otro",
  nombre_pto_recepcion: "otro",
  nombre_instancia_recepcion: "otro",
  admision: "otro",
  canal: "otro",
  marcacion: "otro",
  a_favor_de: "otro",
  correo_usuario_radico: "otro",
  email_usuario: "otro",
};
//#region ALIAS PARA MARCACION
// const marcation = [
//   "Si la queja o reclamo corresponde a una réplica, es decir, si el consumidor financiero solicita reabrir la queja o reclamo por inconformidad con la respuesta dada por la entidad vigilada y/o el defensor del consumidor financiero, dentro de los dos (2) meses siguientes a la fecha final de cierre de la última respuesta",
//   "Si la queja o reclamo fue reclasificada por la entidad vigilada respecto de lo que fue remitido a la superintendencia Financiera de Colombia a través del Formato “Smartsupervision - Interposición de queja o reclamo”",
//   "Si el caso fue cerrado por la entidad vigilada por no ser una queja o reclamo sino otro tipo de petición ante la entidad vigilada",
//   "Si la queja o reclamo fue cerrada por falta de competencia",
//   "Si la queja o reclamo fue cerrada por falta de documentación a ser aportada por el consumidor financiero",
//   "Si la queja o reclamo está en proceso de completar su documentación por parte del consumidor financiero",
//   "Si la queja fue cerrada por estar repetida en Smartsupervision",
//   "Si para la queja o reclamo se encuentra en curso una conciliación",
//   "Si el caso fue cerrado por la entidad vigilada por no ser una queja o reclamo sino una demanda",
// ];
// const alias = [
//   "Queja con réplica",
//   "Queja reclasificada",
//   "No es queja, otro tipo de petición",
//   "Falta de competencia",
//   "Falta de documentación a ser aportada por CF",
//   "Completar documentación por CF",
//   "Queja repetida",
//   "Queja en conciliación",
//   "No es queja, es una demanda",
// ];
//#endregion
export default {
  components: { Calendar },
  props: ["loading", "columns"],
  data() {
    return {
      groupActive: "entidad",
      optionActual: "",
      flag: true,
      selectedKeys: 0,
      openKeys: [],
      groupFilters: [],
      filters: [],
      showModal: false,
      filterSet: null,
      rangeDates: {},
      flagColombia: false,
      relColombia: ["nombre_departamento", "nombre_municipio"],
    };
  },
  created() {
    // console.log(this.columns);

    // Construimos el objeto que tendrá la configuración de los filtros
    this.rangeDates = {
      date_from: moment().set(new Date()).subtract(7, "d").format("YYYY-MM-DD"),
      date_to: moment().set(new Date()).format("YYYY-MM-DD"),
    };
    this.loadFilterSet();
  },
  mounted() {
    this.generateGroups();
  },
  methods: {
    onSearch() {
      this.showModal = !this.showModal;
    },
    generalFilter(ev) {
      this.rangeDates = {
        date_from: ev[0],
        date_to: ev[1],
      };
      if (!this.showModal) this.applyFilters();
    },
    generateGroups() {
      this.groupFilters = this.columns.map(({ ...item }) => {
        let newElement = item;
        if (item.type === "NUMBER") {
          newElement.options = [];
          newElement.options = this.isSwitch({ ...item });
        }
        if (item.data) {
          if (
            item.data.length <= 2 &&
            item.name !== "correo_usuario_radico" &&
            item.name !== "email_usuario" &&
            item.name !== "desistimiento_queja" &&
            item.name !== "tipo_fraude"
          ) {
            if (item.name !== "marcacion") newElement.type = "SELECT-CHECK";
            newElement.options = [];
            newElement.options = this.isSwitch({ ...item });
          }
        }
        newElement.group = this.getGroup(item.name);
        // console.log(newElement);
        return newElement;
      });
    },
    // Para campos que son true o false, si o no, 1 o 0.
    isSwitch(item) {
      // console.log(item.name);

      switch (item.name) {
        case "is_sync":
          return [
            { name: "Queja sincronizada", id: 1 },
            { name: "Queja no sincronizada", id: 2 },
          ];
        case "tutela":
          return [
            { name: "Con tutela", id: "SI" },
            { name: "Sin tutela", id: "NO" },
          ];
        case "anexo_queja":
          return [
            { name: "Queja con anexo", id: 1 },
            { name: "Queja sin anexo", id: 2 },
          ];
        case "acknowledgment":
          return [
            { name: "Queja con anexo de acuso de recibo", id: 1 },
            { name: "Queja sin anexo de acuso de recibo", id: 2 },
          ];
        case "lgbtiq":
          return [
            { name: "LGTBIQ", id: "SI" },
            { name: "NO LGTBIQ", id: "NO" },
          ];

        case "escalamiento_DCF":
          return [
            { name: "Escalada", id: "SI" },
            { name: "No escalada", id: "NO" },
          ];
        case "prorroga_queja":
          return {
            0: {
              style: {
                color: "#f50",
                margin: ".5em 0 0 2em",
              },
              label: <strong>Mínimo</strong>,
            },
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: {
              style: {
                color: "#f50",
                margin: ".5em 0 0 -2em",
              },
              label: <strong>Máximo</strong>,
            },
          };
        case "replica":
          return [
            { name: "Con réplica", id: "SI" },
            { name: "Sin réplica", id: "NO" },
          ];
        case "queja_expres":
          return [
            { name: "Queja exprés", id: "SI" },
            { name: "No queja exprés", id: "NO" },
          ];
        case "producto_digital":
          return [
            { name: "Producto digital", id: "SI" },
            { name: "No producto digital", id: "NO" },
          ];
        default:
          break;
      }
    },
    getGroup(element) {
      let group = "";
      Object.entries(templateJSON).forEach(([key, value]) => {
        if (element === key) group = value;
      });
      console.log(group);

      return group;
    },
    sliderChange(event, itemName) {
      this.filterSet[itemName].data = [event];
    },
    dateSelected(e, itemName) {
      this.filterSet[itemName].data = e;
    },
    applyFilters() {
      this.showModal = false;
      let resultFilter = {};
      // Obtenemos todas las columnas y las convertimos en formato para el backend
      const nameColumns = Object.keys(this.filterSet);

      nameColumns.forEach((item) => {
        resultFilter = {
          ...resultFilter,
          ...this.convertTypeFilter(
            item,
            this.filterSet[item].type,
            this.filterSet[item].data
          ),
        };
      });

      // Se agrega el rango de fechas para la consulta
      resultFilter["fecha_creacion"] = {
        filter_type: "date",
        operator: "AND",
        condition1: {
          date_from: this.rangeDates.date_from,
          date_to: null,
          filter_type: "date",
          type: "greaterThanOrEqual",
        },
        condition2: {
          date_from: this.rangeDates.date_to,
          date_to: null,
          filter_type: "date",
          type: "lessThanOrEqual",
        },
      };

      this.$emit("notify-filters", {
        filter_model: resultFilter,
        filterSet: this.filterSet,
      });
    },
    setMultiSelect(ev, name) {
      this.filterSet[name].data = ev;
      if (name === "nombre_pais") {
        this.flagColombia = this.filterSet["nombre_pais"]?.data?.includes(
          "COLOMBIA"
        )
          ? true
          : false;
      }
      //  this.totalFiltersSelected();
    },
    deleteFilter(filterIdx, dataIdx) {
      this.selectedKeys = 0;
      if (dataIdx !== -1) this.filterSet[filterIdx].data.splice(dataIdx, 1);
      else this.filterSet[filterIdx].data = null;
      setTimeout(() => {
        this.totalFiltersSelected();
      }, 7);
      this.applyFilters();
    },
    radioCleaned(ev) {
      this.filterSet[ev].data = null;
      this.totalFiltersSelected();
    },
    auxConvertTypeFilter(name_att, data) {
      switch (name_att) {
        case "is_sync":
          return {
            filter_type: "set",
            values: data.length > 0 && data[0] === 1 ? [true] : [false],
          };
        case "tutela":
          let values = [];
          if (data.length === 1) values = data[0] == "SI" ? [1] : [2];
          else if (data.length === 2) values = [1, 2];
          return {
            filter_type: "set",
            values: values,
          };
        case "anexo_queja":
          return {
            filter_type: "set",
            values: data.length > 0 && data[0] === 1 ? [true] : [false],
          };
        case "acknowledgment":
          return {
            filter_type: "set",
            values:
              data.length > 1
                ? ["getAll"]
                : data.length > 0 && data[0] === 1
                ? [true]
                : [false],
          };
      }
    },
    convertTypeFilter(name_att, filter_type, data) {
      let resJSON = {};
      // Convertir un campo en su valor conocido por el backend
      if (
        (filter_type === "SELECT" || filter_type === "SELECT-CHECK") &&
        data?.length > 0
      ) {
        // Extra condition: If nombre_pais not contains Colombia then not to add nombre_municipio and nombre_departamento
        if (
          (this.relColombia.includes(name_att) && this.flagColombia) ||
          !this.relColombia.includes(name_att)
        ) {
          if (
            name_att === "is_sync" ||
            name_att === "tutela" ||
            name_att === "acknowledgment" ||
            name_att === "anexo_queja"
          ) {
            resJSON[name_att] = this.auxConvertTypeFilter(name_att, data);
            // }// else if (name_att === "marcacion") {
            //   resJSON[name_att] = {
            //     filter_type: "set",
            //     values: this.revertAliasMarcation(data),
            //   };
          } else {
            resJSON[name_att] = {
              filter_type: "set",
              values: data,
            };
          }
        }
      } else if (filter_type === "TEXT") {
        if (data === null || data === "") return null;
        resJSON[name_att] = {
          filter_type: "text",
          type: "contains",
          filter: data,
        };
      } else if (filter_type === "NUMBER" && name_att != "prorroga_queja") {
        if (data === null) return null;

        resJSON[name_att] = {
          filter_type: "number",
          type: "equals",
          filter: data === "SI" ? 1 : 2,
          filterTo: null,
        };
      } else if (filter_type === "NUMBER" && name_att === "prorroga_queja") {
        if (data === null) return null;

        if (data[0].toString() == "0") {
          resJSON[name_att] = {
            filter_type: "text",
            type: "blank_null",
            filter: null,
            filterTo: null,
          };
        } else {
          resJSON[name_att] = {
            filter_type: "number",
            type: "equals",
            filter: data[0].toString(),
            filterTo: null,
          };
        }
      } else if (filter_type === "DATETIME") {
        if (data === null || data === "") return null;
        resJSON[name_att] = {
          filter_type: "date",
          type: "greaterThanOrEqual",
          date_from: data,
          date_to: null,
        };
      }
      return resJSON;
    },
    active(item) {
      this.optionActual = "";
      this.groupActive = item;
    },
    loadFilterSet() {
      this.filterSet = {};
      this.$props.columns.forEach((el) => {
        this.filterSet[el.name] = {
          type:
            el.name === "tutela" ||
            el.name === "queja_expres" ||
            el.name === "anexo_queja" ||
            el.name === "acknowledgment" ||
            el.name === "producto_digital" ||
            el.name === "lgbtiq" ||
            el.name === "is_sync"
              ? "SELECT-CHECK"
              : el.type,
          data: el.type === "SELECT" ? [] : null,
          description: el.description,
          //data: el.type === "SELECT" ? el.data : null,
        };
      });
    },
    cleanFilters() {
      this.loadFilterSet();
      this.totalFiltersSelected();
      this.applyFilters();
    },
    optionActive(option) {
      this.optionActual = option;
    },
    updateTags() {
      this.totalFiltersSelected();
    },
    totalFiltersSelected() {
      this.selectedKeys = 0;
      const filters = Object.values(this.filterSet);
      for (const filterObj of filters) {
        const filter = filterObj.data;

        if (filter) {
          if (Array.isArray(filter)) {
            let length = filter.length;
            this.selectedKeys += length;
          } else {
            this.selectedKeys += 1;
          }
        }
      }
    },
    getTag(filter) {
      if (
        filter.description == "Sincronizada" ||
        filter.description == "Anexo queja"
      )
        return filter.description;
      if (filter.data.length === 1)
        return filter.description + " " + filter.data[0];
      else return filter.description;
    },
    setAliasMarcationFilter(item) {
      let data = [];
      item.data.forEach((element) => {
        if (marcation.includes(element.marcacion)) {
          let idx = marcation.indexOf(element.marcacion);
          data.push({ marcacion: alias[idx] });
        } else {
          data.push(element);
        }
      });
      return data;
    },
    revertAliasMarcation() {
      if (this.filterSet.marcacion.data.length > 0) {
        let data = [];
        this.filterSet.marcacion.data.forEach((element) => {
          if (alias.includes(element)) {
            let idx = alias.indexOf(element);
            data.push(marcation[idx]);
          } else {
            data.push(element);
          }
        });
        return data;
      }
    },
  },
  computed: {
    getAllGroups: function () {
      let groups = [];
      Object.entries(templateJSON).forEach(([key, value]) => {
        if (!groups.includes(value)) groups.push(value);
      });
      return groups;
    },
    option: function () {
      return this.groupFilters[this.optionActual];
    },
  },
  watch: {},
};
</script>

<style scoped>
.filters {
  margin-top: 2em;
  height: 20em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "main center left";
  grid-gap: 0.1em;
  border-top: solid 1px rgba(0, 0, 0, 0.164);
  border-bottom: solid 1px rgba(0, 0, 0, 0.164);
}
.center-menu {
  padding: 1em;
  grid-area: center;
  border-left: solid 1px rgba(0, 0, 0, 0.164);
  border-right: solid 1px rgba(0, 0, 0, 0.164);
}
.left {
  padding: 1em 0.2em;
  grid-area: left;
}
.main-menu {
  padding: 1em;
  position: relative;
  grid-area: main;
  width: 100%;
}
.sub-menu {
  position: absolute;
}

.calendar {
  width: 100%;
  height: 100%;
}
</style>

<style>
.left .select .checkboxLayer {
  position: absolute;
  display: block;
  top: -3em;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border: none;
  box-shadow: none;
}
.left .select .checkBoxContainer {
  height: 14em;
}
.left .select > button {
  display: none;
}
.ant-tag {
  white-space: normal;
}
</style>